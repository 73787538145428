import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { object, string } from 'yup';
import clsx from "clsx";
import { useRouter } from "next/router";
import { config } from "@/src/utils/config";
import { Submit, PhoneInput } from "@/components/formComponents/MaterialFormInputs";
import { loginAction, resendVerifyCodeAction } from "@/redux/actions/authActions";
import styles from './Authentication.module.scss';
import ErrorMsg from './Error';
import { PasswordEyeSVG } from './AuthSVG';
import Dots from './DotsAnimation';
import {
    LOGIN_LOAD,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    GOOGLE_SIGNUP_SIGNIN_SUCCESS,
    GOOGLE_SIGNUP_SIGNIN_FAIL,
    FB_SIGNUP_SIGNIN_SUCCESS,
    FB_SIGNUP_SIGNIN_FAIL,
    APPLE_SIGNUP_SIGNIN_SUCCESS,
    APPLE_SIGNUP_SIGNIN_FAIL,
} from "@/redux/types";
import useForm from './useForm';
import usePhoneInputDecorator from './usePhoneInputDecorator';
import { useInputSelection } from '@/src/utils/customHooks/useInputSelection';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { constants } from '@/src/utils/constants';
import ReCAPTCHA from 'react-google-recaptcha';
import useAuthHook from '@/containers/Dashboard/hooks/useAuthHook';
const Login = ({ loginOption, setAuthOpen, changeModalType,setLoginOption, changePhoneLogin }) => {
    const router = useRouter();
    const [showPassword, setShowPassword] = useState(false);
    const [loginType, setLoginType] = useState(false)
    const isEmail = loginOption === 'email';
    const authStore = useSelector(state => state.authReducer);
    const dispatch = useDispatch();
    const captchaRef = useRef(null) 
    const [captchaCode, setCaptcaCode] = useState();
    const onChangeReCaptcha = (e) => {
        setCaptcaCode(e)
    }
    const { reFetchProfile } = useAuthHook({
        isCalled:true
    });
    const [submitCount, setSubmitCount] = useState(0);
    const [backendErr, setBackendErr] = useState({
        phone: null,
        email: null,
        password: null
    });
    const phoneInputHelper = useRef(false);

    const initVals = {
        password: '',
        email: '',
        phone: ''
    };

    const loginSchema = object({
        password: string()
            .min(8, 'Must be at least 8 characters long')
            .max(20, 'Must not exceed 20 characters'),
            // .required('Required'),
        email: string()
            .email('Must be valid email address')
            .max(100, 'Must not exceed 100 characters')
            .required('Required'),
        phone: string()
            .required('Required')
            .test('valid-phone', 'Invalid phone number', value => parsePhoneNumber("+"+value).valid),
    });

    const myForm = useForm(initVals, loginSchema);

    const [
        phoneNumber,
        phoneInputKey,
        handleChangePhoneDecorated,
        handleBlurPhoneDecorated
    ] = usePhoneInputDecorator(myForm.handleChange, myForm.handleBlur);

    const loginWitOtp = () => {
        setLoginType(state => !state)
    }
    const handleSubmit = async evt => {
        evt.preventDefault();
        if(loginType){
            dispatch(resendVerifyCodeAction({
                country_calling_code: '+' + phoneNumber[0],
                phone: phoneNumber[1],
                'g-recaptcha-response': captchaCode,
                type: 'phone_verification'
            }))
            changePhoneLogin(phoneNumber);
            return;
        }
        setSubmitCount((count) => {
            return count + 1;
        });
        const phoneData = isEmail ? null : {
            country_calling_code: '+' + phoneNumber[0],
            phone: phoneNumber[1],
            'g-recaptcha-response': captchaCode,
        };
        const values = {
            password: myForm.values.password,
            ...(isEmail ? { email: myForm.values.email } : phoneData),
        };
        dispatch(loginAction(values));
    };

    const updateBackendError = (field) => {
        setBackendErr((prev) => {
            return {
                ...prev,
                [field]: authStore.message
            };
        });
    };
    useEffect(() => {
        if(authStore.statusCode === 202) {
            setBackendErr({
                phone: null,
                email: null,
                password: null
            });
            changeModalType();
        }
    },[backendErr.password])
    // const Input = useInputSelection();

    useEffect(() => {
        // TODO: talk to backend - it's difficult to differentiate which field the error belongs to
        if ([LOGIN_SUCCESS, GOOGLE_SIGNUP_SIGNIN_SUCCESS, FB_SIGNUP_SIGNIN_SUCCESS, APPLE_SIGNUP_SIGNIN_SUCCESS].includes(authStore.type)) {
            setAuthOpen(false);
            reFetchProfile();
            if (router?.asPath?.includes("redirect_url")) {
                router.push(router.query.redirect_url);
            }
        } else if ([LOGIN_FAIL, GOOGLE_SIGNUP_SIGNIN_FAIL, FB_SIGNUP_SIGNIN_FAIL, APPLE_SIGNUP_SIGNIN_FAIL].includes(authStore.type)) {
                updateBackendError('password');
        }
       setCaptcaCode(null)
    }, [authStore.type]);

    useEffect(() => {
        setCaptcaCode(null)
        const timeoutID = setTimeout(() => {
            setBackendErr({
                phone: null,
                email: null,
                password: null
            });
        }, 4000);
        return () => {
            clearTimeout(timeoutID);
        };
    }, [backendErr.phone, backendErr.email, backendErr.password]);

    const isSubmitDisabled = () => {
        if(loginType && captchaRef?.current?.getValue()){
            return false
        } else {
            const values = { ...myForm.values };
            const errors = { ...myForm.errors };
            if (isEmail) {
                delete values['phone'];
                delete errors['phone'];
            } else {
                delete values['email'];
                delete errors['email'];
            }
            const haveEmpty = Object.values(values).some(value => !value);
            const haveError = Object.values(errors).some(error => error);
            const haveCaptcha = isEmail ? true : captchaRef?.current?.getValue();
            return (haveEmpty || haveError || !haveCaptcha);
        }
    };

    useEffect(() => {
        if (phoneInputHelper.current) {
            document.querySelector('.phone-input-component input')?.focus();
        } else {
            phoneInputHelper.current = true;
        }
    }, [phoneInputKey]);
    useEffect(() => {
        if(myForm.values.email.startsWith('+')){
            setLoginOption('phone')
            myForm.handleChange(null, 'email', ''); 
        }
        const token = captchaRef?.current?.getValue();
        if(authStore.type === LOGIN_FAIL && token){
          captchaRef.current.reset();
        }
    },[myForm.values])

    return (
        <>
        {/* if they change thier mind */}
            {/* <div className={styles.auth_title}>
                Enter your {isEmail ? 'email' : 'mobile number'}
            </div > */}
            <form className={styles.auth_form} onSubmit={handleSubmit}>
                <ul className={styles.auth_form_ul}>
                    {isEmail
                        ? (
                            <li className={styles.auth_form_item}>
                                <input
                                    id='email'
                                    name='email'
                                    type='email'
                                    placeholder='Email address'
                                    className={clsx(styles.auth_form_input)}
                                    onChange={myForm.handleChange}
                                    onBlur={myForm.handleBlur}
                                    value={myForm.values.email}
                                />
                                {myForm.errors.email &&
                                    <ErrorMsg>{myForm.errors.email}</ErrorMsg>}
                                {backendErr.email && (submitCount > 0) && <ErrorMsg>{backendErr.email}</ErrorMsg>}
                            </li>
                        )
                        : (
                            <li className={styles.auth_form_item}>
                                <PhoneInput
                                    containerClass={clsx(styles.auth_form_item__phone_container,
                                        (myForm.values.phone && styles.auth_form_item__phone_container_selected))}
                                    placeholder='country code+phone number'
                                    value={phoneNumber[0] ? phoneNumber.join('') : ''+phoneNumber[1]}
                                    onChange={handleChangePhoneDecorated}
                                    onBlur={handleBlurPhoneDecorated}
                                    key={phoneInputKey}
                                />
                                {myForm.errors.phone &&
                                    <ErrorMsg>{myForm.errors.phone}</ErrorMsg>}
                                {backendErr.phone && (submitCount > 0) && <ErrorMsg>{backendErr.phone}</ErrorMsg>}
                                <div className={clsx(styles.auth_form_item_otplogin , !loginType && styles.auth_form_item_otpdisabled , Boolean(myForm.values.password) && styles.auth_form_item_disableotpcursor)} onClick={loginWitOtp}>
                                    Login with OTP
                                </div>
                                <p className={styles.auth_form_item_otploginor}>Or</p>
                            </li>
                        )}
                    <li className={styles.auth_form_item}>
                        <input
                            id='password'
                            name='password'
                            type={showPassword ? 'text' : 'password'}
                            placeholder='Password'
                            className={clsx(styles.auth_form_input,
                                styles.auth_form_input_password, Boolean(loginType && !isEmail) && styles.auth_form_item_disableotpcursor)}
                            onChange={myForm.handleChange}
                            onBlur={myForm.handleBlur}
                            value={myForm.values.password}
                        />
                        <span
                            className={styles.auth_form_item__symbol}
                            onClick={() => setShowPassword(currState => !currState)}
                        >
                            <PasswordEyeSVG show={showPassword} />
                        </span>
                        {myForm.errors.password &&
                            <ErrorMsg>{myForm.errors.password}</ErrorMsg>}
                        {backendErr.password && (submitCount > 0) && <ErrorMsg>{backendErr.password}</ErrorMsg>}
                    </li>
                    {!isEmail && (myForm.values.password || loginType) &&
                        <li className={styles.auth_form_item}>
                            <ReCAPTCHA
                                sitekey={constants.dashboardResendTokenRecaptchaKey}
                                onChange={onChangeReCaptcha}
                                className={styles.recaptcha}
                                ref={captchaRef}
                                />
                        </li>
                    }
                    <li className={styles.auth_form_item}>
                        <Submit
                            type="submit"
                            className={styles.auth_form_submit}
                            disabled={isSubmitDisabled()}
                        >
                            {authStore.type === LOGIN_LOAD
                                ? <Dots container={styles.loading} />
                                : 'Log in'}
                        </Submit>
                    </li>
                </ul>
            </form>
        </>
    );
}

export default Login;
