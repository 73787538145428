import React, { memo, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import SearchActionArea from './SearchActionArea';
import StepEnum, { Entities } from './enum';
import useProceedToSearch from './useProceedToSearch';
import useValidationGate from './useValidationGate';
import LosNotification from '@/components/LosNotification/LosNotification';
import { constants } from '@/src/utils/constants';
import InvalidSearchCriteriaException from './exceptions/InvalidSearchCriteriaException';
import Box from '@mui/material/Box';
import DestinationInputMui from './Destination/DestinationInputMui';
import DateRangeInputMui from './DateRange/DateRangeInputMui';
import InputSlotWrapper from './InputSlotWrapper';
import GuestInputMui from './GuestCount/GuestInputMui';


const InputGroupArea = ({ destination, dates, guests, step }) => {

    const [currentStep, setCurrentStep] = step;

    const [destinationState, setDestinationState] = destination;
    const [datesState, setDatesState] = dates;
    const [guestsState, setGuestsState] = guests;
    const [showLosNotification, setShowLosNotification] = useState(false);
    const datesRef = useRef(null);

    const [invalidEntity, setInvalidEntity] = useState(null);

    const { isEntitiesValid } = useValidationGate();

    const onProceedToSearch = useProceedToSearch();

    const onClick = (e) => {
        e.stopPropagation();
        if (currentStep === StepEnum.INTERESTS) {
            setCurrentStep(StepEnum.GUESTS)
            return;
        }

        if (!datesState.isLenghtOfStayValid()) {
            setShowLosNotification(true);
            window.addEventListener('click', () => setShowLosNotification(false), { once: true });
            window.addEventListener('scroll', () => setShowLosNotification(false), { once: true });
        }

        onProceedToSearch(
            destinationState,
            datesState,
            guestsState
        ).catch(error => {
            if (error instanceof InvalidSearchCriteriaException) {
                setInvalidEntity(error.context);
            }
        });

        if (isEntitiesValid()) {
            setCurrentStep();
        }
    };

    const resetInvalidEntity = () => setInvalidEntity(null);

    return (
        <div
            className={clsx(
                'omnisearch_content_input-group-area',
                isEntitiesValid() && 'omnisearch_content_input-group-area--valid'
            )}
        >
            <Box
                sx={{
                    flex: {
                        xs: 350 / 880,
                        md: 410 / 880,
                    },
                    ':hover': {
                        cursor: 'pointer',
                    },
                    position: 'relative',
                    height: '100%',
                }}
            >
                <InputSlotWrapper
                    isActive={[StepEnum.INITIAL, StepEnum.LOCATION].includes(currentStep)}
                    borderProps={{
                        borderRight: '1px solid #FFFFFF',
                    }}
                    adjacent={{ right: currentStep === StepEnum.DATES }}
                >
                    <DestinationInputMui
                        value={destinationState}
                        onChange={setDestinationState}
                        isActive={[StepEnum.INITIAL, StepEnum.LOCATION].includes(currentStep)}
                        setCurrentStep={setCurrentStep}
                    />
                </InputSlotWrapper>
            </Box>


            <Box
                sx={{
                    flex: '0 0 0',
                    height: '36px',
                    alignSelf: 'center',
                    borderLeft: '1px solid #E9E9EB',
                }}
            />


            <Box
                ref={datesRef}
                sx={{
                    flex: {
                        xs: 220 / 880,
                        md: 210 / 880,
                    },
                    position: 'relative',
                    ':hover': {
                        cursor: 'pointer',
                    },
                }}
            >
                <InputSlotWrapper
                    isActive={currentStep === StepEnum.DATES}
                    borderProps={{
                        borderRight: '1px solid #FFFFFF',
                        borderLeft: '1px solid #FFFFFF',
                    }}
                    adjacent={{
                        right: currentStep === StepEnum.GUESTS,
                        left: [StepEnum.INITIAL, StepEnum.LOCATION].includes(currentStep),
                    }}
                >
                    <DateRangeInputMui
                        value={datesState}
                        onChange={setDatesState}
                        isActive={currentStep === StepEnum.DATES}
                        setCurrentStep={setCurrentStep}
                    />
                </InputSlotWrapper>
            </Box>

            <Box
                sx={{
                    flex: '0 0 0',
                    height: '36px',
                    alignSelf: 'center',
                    borderLeft: '1px solid #E9E9EB',
                }}
            />

            <Box
                sx={{
                    flex: 260 / 880,
                    flex: {
                        xs: 310 / 880,
                        md: 260 / 880,
                    },
                    flexShrink: 0,
                    position: 'relative',
                    ':hover': {
                        cursor: 'pointer',
                    },
                }}
            >
                <InputSlotWrapper
                    isActive={currentStep === StepEnum.GUESTS}
                    borderProps={{
                        borderLeft: '1px solid #FFFFFF',
                    }}
                    adjacent={{ left: currentStep === StepEnum.DATES }}
                    sx={{
                        '&:hover:has(#omni-search-button:hover)': {
                            '::after': {
                                bgcolor: 'transparent',
                            },
                            '::before': {
                                display: 'none',
                            },
                        },
                    }}
                >
                    <Box
                        sx={{ display: 'flex', justifyContent: 'space-between', }}
                    >
                        <GuestInputMui
                            value={guestsState}
                            onChange={setGuestsState}
                            isActive={currentStep === StepEnum.GUESTS}
                            setCurrentStep={setCurrentStep}
                        />
                        <Box
                            id={'omni-search-button'}
                            sx={{
                                display: 'flex',
                                flex: '0 0 auto',
                            }}
                        >
                            <SearchActionArea
                                onClick={onClick}
                                invalidEntity={invalidEntity}
                                resetInvalidEntity={resetInvalidEntity}
                            />
                        </Box>
                    </Box>
                </InputSlotWrapper>
            </Box>

            <LosNotification
                anchorEl={datesRef.current}
                open={showLosNotification}
                text={`You can only book stays for ${Math.round(constants.lengthOfStay / 86400000)} days or less.`}
                offset={[10, 14]}
            />
        </div>
    );
};

export default memo(InputGroupArea);
