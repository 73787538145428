import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Submit } from "@/components/formComponents/MaterialFormInputs";
import clsx from "clsx";
import styles from './Authentication.module.scss';
import { verifyCodeAction, resendVerifyCodeAction, resetAuth, refreshTokenAction } from '@/redux/actions/authActions';
import ErrorMsg from './Error';
import Dots from './DotsAnimation';
import {
    VERIFY_CODE_SUCCESS,
    VERIFY_CODE_FAIL,
    VERIFY_CODE_LOAD,
    RESEND_VERIFY_CODE_SUCCESS,
    RESEND_VERIFY_CODE_FAIL,
    RESEND_VERIFY_CODE_LOAD,
} from "@/redux/types";
import { constants } from '@/src/utils/constants';
import ReCAPTCHA from 'react-google-recaptcha';


const PhoneVerification = ({ phoneNumber, setAuthOpen, setScreen, children, password, passwordChange = true }) => {
    const [code, setCode] = useState(Array(4).fill(''));
    const inpRef = useRef([]);
    const [timer, setTimer] = useState(60);
    const timerRef = useRef(null);
    const captchaRef = useRef(null) 
    const [backendErr, setBackendErr] = useState(null);
    const [userId, setUserId] = useState(null);
    const [submitCount, setSubmitCount] = useState(0);
    const [smsCount, setSmsCount] = useState(1);
    const [captchaCode, setCaptcaCode] = useState();
    const authStore = useSelector(state => state.authReducer);
    const dispatch = useDispatch();
    const onChangeReCaptcha = (e) => {
        setCaptcaCode(e)
    }
    const handleSubmit = () => {
        setSubmitCount((count) => {
            return count + 1;
        });
        const values = {
            id: userId,
            verify_code: code.join(''),
            type: !children ? "phone_verification" : "reset_password",
            ...(password && passwordChange && {password: password}),
            ...(setAuthOpen && passwordChange && {login_with_otp: true})
        };
        dispatch(verifyCodeAction(values));
    };

    const handleResend = () => {
        const token = captchaRef.current.getValue();
        if(!token) {
            return
        }
        if(timer === 0 && captchaCode) {
            setBackendErr(null)
            const values = {
                country_calling_code: '+' + phoneNumber[0],
                phone: phoneNumber[1],
                'g-recaptcha-response': captchaCode,
                ...(!children && {type: "phone_verification"})
            };
            dispatch(resendVerifyCodeAction(values));
            setTimer(60);
            setSubmitCount((count) => {
                return count + 1;
            });
            captchaRef.current.reset();
        }
    };

    const handleKeyDown = (evt) => {
        if (evt.key === 'Backspace' || evt.key === 'Delete') {
            setCode((state) => {
                const newState = [...state];
                newState[evt.target.id[1]] = '';
                return newState;
            });
            return;
        }
        if (evt.key === 'ArrowRight' || evt.key === 'ArrowLeft') {
            return;
        }
        evt.preventDefault();
        if (!isNaN(evt.key)) {
            setCode((state) => {
                const newState = [...state];
                newState[evt.target.id[1]] = evt.key;
                return newState;
            });
            const currDigit = +evt.target.id[1];
            if ((code.length - 1) === currDigit) {
                inpRef.current[currDigit].blur();
            } else {
                inpRef.current[currDigit + 1].focus();
            }
        }
    };

    const checkBorderModifier = (error, filled) => {
        if (error) {
            return styles.phone_verification_form_input_red;
        } else if (filled) {
            return styles.phone_verification_form_input_black;
        } else {
            return null;
        }
    };

    useEffect(() => {
        if(Boolean(authStore.user_id)){
            setUserId(authStore.user_id)
            return;
        }
        if(authStore.data){
            setUserId(authStore.data['user_id'] || authStore.data['_id']);
        }
    }, [authStore]);

    useEffect(() => {
        timerRef.current = setInterval(() => setTimer(prev => prev - 1), 1000);
        return () => clearInterval(timerRef.current);
    }, [smsCount]);

    useEffect(() => {
        if (timer <= 0) {
            clearInterval(timerRef.current);
            timerRef.current = null;
        }
    });

    useEffect(() => {
        if (authStore.type === VERIFY_CODE_SUCCESS) {
             if(setAuthOpen) {
                dispatch(refreshTokenAction());
                setAuthOpen(false)
             } 
             if(setScreen) {
                setScreen('final');
             }
        } else if ((authStore.type === RESEND_VERIFY_CODE_SUCCESS) && (submitCount > 0)) {
            clearInterval(timerRef.current);
            timerRef.current = null;
            setSmsCount(count => count + 1);
            setTimer(60);
        } else if ([VERIFY_CODE_FAIL, RESEND_VERIFY_CODE_FAIL].includes(authStore.type)) {
            setBackendErr(authStore.message);
        }
    }, [authStore.type]);

    useEffect(() => {
        if (backendErr && (submitCount > 0)) {
            const timeoutID = setTimeout(() => setBackendErr(null), 4000);
            return () => clearTimeout(timeoutID);
        }
    }, [backendErr]);

    return (
        <>
            <div className={styles.phone_verification_subtitle}>
                Enter verification code
            </div>
            <div className={styles.phone_verification_text}>
                <span className={styles.phone_verification_text_inner}>
                    {`A verification code has been sent to +${phoneNumber[0]} ${phoneNumber[1]}`}
                </span>
            </div>
            <div className={styles.phone_verification_code}>
                <form className={styles.phone_verification_form}>
                    <ul className={styles.phone_verification_form_ul}>
                        {code.map((elem, i) => {
                            const borderModifier = checkBorderModifier(backendErr, (elem !== null));
                            return (
                                <li className={styles.phone_verification_form_item} key={i}>
                                    <input
                                        id={`_${i}`}
                                        type='number'
                                        min='0'
                                        max='9'
                                        placeholder='0'
                                        className={clsx(styles.phone_verification_form_input, borderModifier)}
                                        value={elem}
                                        ref={el => inpRef.current[i] = el}
                                        onKeyDown={handleKeyDown}
                                        onChange={() => { }}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </form>
                {backendErr && <ErrorMsg>{backendErr}</ErrorMsg>}
            </div>
            {children}
            <Submit
                type='button'
                onClick={handleSubmit}
                className={styles.auth_form_submit}
                disabled={(code.some(el => !el) || timer <= 0 || (password === undefined ? false : !password))}
            >
                {authStore.type === VERIFY_CODE_LOAD
                    ? <Dots container={styles.loading} />
                    : 'Verify'}
            </Submit>
            <div className={styles.phone_verification_timer} ref={timerRef}>
                {`Verification code expires in ${timer} seconds`}
            </div>
            <ReCAPTCHA
                    sitekey={constants.dashboardResendTokenRecaptchaKey}
                    onChange={onChangeReCaptcha}
                    className={styles.recaptcha}
                    ref={captchaRef}
            />
            <div className={styles.phone_verification_resend}>
                <a
                    className={clsx(styles.phone_verification_resend_inner ,timer !== 0 &&  styles.phone_verification_resend_inner_wait)}
                    onClick={handleResend}
                >
                    Resend verification code
                </a>
            </div>
        </>
    );
};

export default PhoneVerification;
