import { memo, useCallback, useEffect, useMemo } from "react";
import { useSelector } from 'react-redux';
import Box from "@mui/material/Box";

import InputSlotOmni from "../InputSlotOmni";
import useDestinationIput from "./useDestinationInput";
import StepEnum from '../enum';


function DestinationInputMui({ value, isActive, onChange, setCurrentStep }) {
    const suggestionResult = useSelector(state => state.geo_location);
    const { onChange: onChangeHandler, inputRef, onClear } = useDestinationIput({ onDestinationInputChange: onChange, setStep: setCurrentStep });

    const isEmpty = value.inputValue.length <= 0;

    const onActivateStep = useCallback(() => {
        setCurrentStep(() => {
            if (Array.isArray(suggestionResult.data) && suggestionResult.data.length > 0) {
                return StepEnum.LOCATION;
            }
            return StepEnum.INITIAL;
        });
    }, [setCurrentStep, suggestionResult.data]);

    const onClick = useCallback(() => {
        onActivateStep();
        inputRef?.current?.focus();
    }, [onActivateStep]);

    const slot = useMemo(() => (
        <Box
            ref={inputRef}
            test-id='hp_whereTo'
            component={'input'}
            type={'text'}
            placeholder={'Search destinations'}
            onChange={onChangeHandler}
            value={value.inputValue}
            sx={{
                outline: 'none',
                background: 'none',
                border: 'none',
                fontFamily: 'inherit',
                width: '100%',
                height: '22px',
                color: 'var(--color-shade-black)',
                textOverflow: 'ellipsis',
                fontSize: '14px',
                lineHeight: '22px',
                fontWeight: 600,
                '::placeholder': {
                    color: 'var(--color-dark-grey)',
                    fontFamily: 'inherit',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                    letterSpacing: '0.14px',
                },
            }}
        />
    ), [onChangeHandler, value.inputValue]);

    useEffect(() => {
        if (isEmpty) {
            setCurrentStep(() => {
                return StepEnum.INITIAL;
            });
        }
    }, [isEmpty]);

    return (
        <Box
            onClick={onClick}
            sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                flex: 1,
                padding: '11px 12px 11px 24px',
            }}
        >
            <InputSlotOmni
                title={'Where to?'}
                subtitle={'Search destinations'}
                slot={slot}
                onClear={onClear}
                isActive={isActive}
                isEmpty={isEmpty}
            />
        </Box>
    );
}

export default memo(DestinationInputMui);
