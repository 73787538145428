import { memo, useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import pluralize from 'pluralize';

import StepEnum from '../enum';
import GuestModel from "./GuestModel";
import InputSlotOmni from "../InputSlotOmni";


function GuestInputMui({ value, isActive, onChange, setCurrentStep }) {
    const isEmpty = useMemo(() => !value.isVisited, [value]);

    const renderValue = useMemo(() => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    color: isEmpty ? 'var(--color-dark-grey)' : 'var(--color-shade-black)',
                    fontFamily: 'inherit',
                    fontSize: '14px',
                    fontWeight: isEmpty ? 400 : 600,
                    lineHeight: '22px',
                    letterSpacing: '0.14px',
                }}
            >
                {pluralize('guest', value.totalCount, true)} · {pluralize('room', value.roomCount, true)}
            </Box>
        );
    }, [value, isEmpty]);

    const onClear = useCallback(() => {
        onChange(new GuestModel({ isVisited: false }));
    }, [onChange]);

    const onClick = useCallback(() => {
        setCurrentStep(StepEnum.GUESTS);
        onChange((state) => new GuestModel({
            ...state,
            isVisited: true
        }));
    }, [setCurrentStep, onChange]);

    return (
        <Box
            onClick={onClick}
            sx={{
                flex: 1,
                padding: '11px 12px 11px 24px',
            }}
        >
            <InputSlotOmni
                title={'Who'}
                subtitle={'Add guests'}
                value={renderValue}
                onClear={onClear}
                isActive={isActive}
                isEmpty={isEmpty}
            />
        </Box>
    );
}

export default memo(GuestInputMui);
