import { memo } from "react";
import Box from '@mui/material/Box';

function InputSlotWrapper({
    children,
    isActive,
    borderProps = {},
    adjacent = {},
    sx = {},
}) {
    const adjacentRight = adjacent.right ? {
        height: 'unset',
        mt: 0,
        top: -2,
        bottom: -2,
        left: -1,
        right: -32,
        borderRadius: 0,
        background: 'linear-gradient(270deg, #FDE9F1 0, #FDE9F1 50%, transparent 50%, transparent 100%)',
        borderColor: '#FFFFFF',
    } : {};

    const adjacentLeft = adjacent.left ? {
        height: 'unset',
        mt: 0,
        top: -2,
        bottom: -2,
        left: -32,
        right: -1,
        borderRadius: 0,
        background: 'linear-gradient(90deg, #FDE9F1 0, #FDE9F1 50%, transparent 50%, transparent 100%)',
        borderColor: '#FFFFFF',
    } : {};

    return (
        <Box
            sx={{
                position: 'relative',
                // margin: '0 -2px',
                '::after': {
                    position: 'absolute',
                    zIndex: isActive ? 1 : 0,
                    top: -2,
                    bottom: -2,
                    left: 0,
                    right: 0,
                    content: '""',
                    borderRadius: '37px',
                    bgcolor: isActive ? '#FFFFFF' : 'transparent',
                    boxShadow: (
                        isActive
                            ? '0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14)'
                            : 'none'
                    ),
                },
                '::before': {
                    display: isActive ? 'block' : 'none',
                    position: 'absolute',
                    zIndex: adjacent.right ? 1 : 0,
                    height: '36px',
                    top: '50%',
                    mt: '-18px',
                    left: -1,
                    right: -1,
                    content: '""',
                    ...borderProps,
                    ...adjacentRight,
                    ...adjacentLeft,
                },
                '&:hover': {
                    '::before': {
                        display: 'block',
                    },
                    '::after': {
                        bgcolor: isActive ? '#FFFFFF' : '#FDE9F1',
                    },
                },
                ...sx,
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    zIndex: isActive ? 2 : 1,
                }}
            >
                {children}
            </Box>
        </Box>
    );
}

export default memo(InputSlotWrapper);
