import { memo, useCallback, useMemo, useRef } from "react";
import moment from 'moment';
import Box from "@mui/material/Box";

import DateRangeModel from "./DateRangeModel";
import StepEnum from '../enum';
import InputSlotOmni from "../InputSlotOmni";


function DateRangeInputMui({ value, isActive, onChange, setCurrentStep }) {
    const inputSlotRef = useRef(null);

    const isEmpty = useMemo(() => (
        Object.values(value).every(date => !Boolean(date))
    ), [value]);

    const renderValue = useMemo(() => {
        const [startDate, endDate] = [moment(value.startDate), moment(value.endDate)];
        const start = startDate.isValid() ? startDate.format('DD MMM') : 'Check-in';
        const end = endDate.isValid() ? endDate.format('DD MMM') : 'Check-out';

        return (
            <Box
                sx={{
                    display: 'flex',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    color: isEmpty ? 'var(--color-dark-grey)' : 'var(--color-shade-black)',
                    fontFamily: 'inherit',
                    fontSize: '14px',
                    fontWeight: isEmpty ? 400 : 600,
                    lineHeight: '22px',
                    letterSpacing: '0.14px',
                }}
            >
                {`${start} - ${end}`}
            </Box>
        );
    }, [value, isEmpty]);

    const onClick = useCallback(() => {
        setCurrentStep(StepEnum.DATES);
    }, [setCurrentStep]);

    const onClear = useCallback(() => {
        onChange(new DateRangeModel());
    }, [onChange]);

    return (
        <Box
            onClick={onClick}
            sx={{
                padding: '11px 12px 11px 24px',
            }}
        >
            <InputSlotOmni
                // ref={inputSlotRef}
                title={'Dates'}
                subtitle={'Add dates'}
                value={renderValue}
                // onClick={onClick}
                onClear={onClear}
                isActive={isActive}
                isEmpty={isEmpty}
            />
        </Box>
    );
}

export default memo(DateRangeInputMui);
