import { forwardRef, memo } from "react";
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import CloseIcon from '@/components/common/icons/Close';


function InputSlotOmni({
    title,
    subtitle,
    value,
    slot,
    prefix,
    // onClick,
    onClear,
    isActive,
    isEmpty,
}, ref) {
    // console.log('isEmpty >> ', isEmpty);

    return (
        <Box
            // ref={ref}
            // onClick={onClick}
            sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            {Boolean(prefix) &&
                <Box
                    sx={{ flex: '0 0 auto' }}
                >
                    {prefix}
                </Box>
            }

            <Box
                sx={{
                    flex: 1,
                }}
            >
                <Box
                    sx={{
                        color: 'var(--color-black)',
                        fontFamily: 'inherit',
                        fontSize: '12px',
                        fontWeight: 600,
                        lineHeight: '150%',
                        letterSpacing: '0.12px',
                    }}
                >
                    {title}
                </Box>
                <Box
                    sx={{
                        color: 'var(--color-dark-grey)',
                        fontFamily: 'inherit',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '22px',
                        letterSpacing: '0.14px',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {/* temporary trash */}
                    {Boolean(slot) ? slot : (isEmpty ? subtitle : value)}
                    {/* {isEmpty ? subtitle : value} */}
                </Box>
            </Box>

            {(isActive && !isEmpty) &&
                <Box
                    sx={{
                        flex: '0 0 auto',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={onClear}
                        sx={{
                            '&.MuiIconButton-root': {
                                '&:hover': {
                                    bgcolor: '#D9D9D9',
                                },
                            },
                        }}
                        size='small'
                    >
                        <CloseIcon sx={{ fontSize: '1rem' }} />
                    </IconButton>
                </Box>
            }
        </Box >
    );
}

export default memo(forwardRef(InputSlotOmni));
